// ************************
// *      SANTINT
// ************************
import swal from 'sweetalert';
import FileSaver from 'file-saver';

const generateDozeFile = () => {
  const table = document.getElementById('tabela');
  const baseTable = document.getElementById('baseTable');
  const amount = document.getElementById('calValue').value;
  const recipeName = Array.from(document.getElementsByTagName('h2'))[0].innerText;
  const ingredientsArray = []

  // Disable if not calculated yet
  if (table.rows[1].cells[2].innerText.length === 0) {
    swal({
      title: 'Błąd',
      text: 'Musisz najpierw obliczyć ilości składników'
    })
    return;
  }

  // create array of ingredients with amounts from table
  for (let x = 1; x < table.rows.length; x++ ) {
      ingredientsArray.push(
        {
          name: table.rows[x].cells[0].innerText,
          amount: table.rows[x].cells[2].innerText.slice(0, -2),
          isBase: baseTable.rows[x].cells[0].innerText === 'true'
        }
        )
  }

  const bases = ingredientsArray.filter(a => a.isBase);
  // Disable if no bases
  if (bases.length < 1) {
    swal({
      title: "Błąd",
      text: "Dozowanie nie jest możliwe dla receptur bez żadnej bazy",
    });
    return;
  }
  // Two bases are possible now so the following doesn't apply anymore
  // Disable if more than one base
  // if (bases.length > 1) {
  //      swal({
  //        title: "Błąd",
  //        text: "Dozowanie nie jest możliwe dla receptur z więcej niż jedną bazą",
  //      });
  //      return;
  // }

  // If more than two bases don't print out the base name
  const baseName = bases.length === 1 ? `@BAS "${bases[0].name}"` : '';

  //colorants 
  const colorants = ingredientsArray.filter(a => a.isBase === false)
  const colorantsSentence = colorants.map(colorant => {
    return `"${colorant.name}" ${colorant.amount}`
  }).join(' ');

  // contents for santint machine
  const content = `
    @RUN
    @PRD "${recipeName}"
    @WGH 1
    @UNT 1 1
    @CLR "${recipeName}"
    ${baseName}
    @CAN "${amount * 1000} ML" ${(amount * 1000).toFixed(2)} $0$
    @FRM ${(amount * 1000).toFixed(2)} $0$
    @CNT ${colorantsSentence}
    @END
  `
  var blob = new Blob([`${content}`], { type: "application/dat;charset=utf-8" });
  FileSaver.saveAs(blob, `${recipeName}-${new Date().toISOString()}.dat`);
}


const dozeButton = document.getElementById('doze-button');
if (dozeButton) {
  dozeButton.addEventListener('click', generateDozeFile);
}
